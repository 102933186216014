import React from 'react';

function Dashboard() {

    // if (window.location.pathname !== '/dashboard') {
    //     window.location.href = '/dashboard';
    // }

    if (window.location.pathname.startsWith('/dashboard') || window.location.pathname !== '/dashboard') {
        window.location.href = '/rum';
    }
    
    return <h2>Dashboard</h2>;
}

export default Dashboard;
