import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Login from './components/auth/Login';
import Logout from './components/auth/Logout';
import Signup from './components/auth/Signup';

import Root from './components/Root';
import Dashboard from './components/pages/Dashboard';
import Logs from './components/pages/backend_devops/Logs';

import RealUserMonitoring from './components/pages/rum/RealUserMonitoring';
import LiveDomain from './components/pages/rum/LiveDomain';

import Visitors from './components/pages/rum/Visitors';

import Reports from './components/pages/rum/Reports';
import CICD from './components/pages/backend_devops/CICD';
import Docker from './components/pages/backend_devops/Docker';
import Kubernetes from './components/pages/backend_devops/Kubernetes';

import AddDomain from './components/pages/rum/AddDomain';
import SetupDomain from './components/pages/rum/SetupDomain';
import ReportsDomain from './components/pages/rum/ReportsDomain';

import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './components/Root.css';
import CloudSpendAWS from './components/pages/spend/aws';
import VerifyEmail from './components/auth/VerifyEmail';
import Errors from './components/pages/rum/Errors';
import Infrastructure from './components/pages/infra/Infrastructure';
import APM from './components/pages/apm/APM';

function AppRoutes() {

    const [token] = useState(Cookies.get('ataivajwt')); // Get token from cookie
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (token) {
            axios.get(`${process.env.REACT_APP_API_URL}/profile`, { headers: { 'Authorization': `${token}` } })
                .then(response => {
                    setUser(response.data);
                })
                .catch(err => console.log(err))
        }
    }, [token]);

    const handleLogout = () => {
        window.location.href = '/logout';
    };

    return (
        <Router>
            <Routes>
                <Route path="logout" element={<Logout />} />
                {token ? (
                    <Route path="/" element={<Root token={token} user={user} handleLogout={handleLogout} />} >
                        <Route path="" element={<Dashboard/>} />
                        <Route path="dashboard" element={<Dashboard/>} />
                        <Route path="logs" element={<Logs/>} />
                        
                        <Route path="rum" element={<RealUserMonitoring/>} />
                        <Route path="rum/:domain/live" element={<LiveDomain />} />
                        <Route path="rum/:domain/visitors" element={<Visitors/>} />
                        <Route path="rum/add" element={<AddDomain/>} />
                        <Route path="rum/:domain/setup/" element={<SetupDomain user={user}/>} />
                        <Route path="rum/:domain/reports/" element={<ReportsDomain user={user}/>} />
                        <Route path="rum/:domain/errors/" element={<Errors user={user}/>} />
                        
                        <Route path="infra" element={<Infrastructure/>} />
                        
                        <Route path="apm" element={<APM/>} />

                        {/* <Route path="reports" element={<Reports/>} />
                        <Route path="cicd" element={<CICD/>} />
                        <Route path="docker" element={<Docker/>} />
                        <Route path="kubernetes" element={<Kubernetes/>} /> */}
                        
                        
                        {/* <Route path="spend/aws" element={<CloudSpendAWS/>} /> */}
                    </Route>
                ) : (
                    // Not logged-in route
                    <>
                        <Route path="login" element={<Login />} />
                        <Route path="login/:message" element={<Login />} />
                        <Route path="sign-up" element={<Signup />} />
                        <Route path="verify-email" element={<VerifyEmail />} />
                        <Route path="verify-email/:uuid/:email" element={<VerifyEmail />} />
                        <Route path="*" element={<Login />} />
                    </>
                )}
            </Routes>
        </Router>
    );
}

export default AppRoutes;
