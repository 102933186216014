import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './AuthForms.css';
import Cookies from 'js-cookie';
import logo1 from '../../assets/logo1_2.webp';
import { FaCheckCircle } from "react-icons/fa";


function Login() {
    const { message } = useParams();

    const [token, setToken] = useState(Cookies.get('ataivajwt')); // Get token from cookie
    const cookie_domain = window.location.hostname === 'localhost' ? 'localhost' : '.ataiva.com';

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();

    if (!window.location.pathname.startsWith("/login")) {
        window.location.href = "/login";
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, { email, password });
            if (response.data && response.data.ataivajwt) {
                const _token = response.data.ataivajwt;
                setAuthCookie(_token);
                setToken(_token);
                // navigate('/');
                window.location.href = "/";
            }
        } catch (error) {
            console.log(error.response.data.message)
            if (error.response && error.response.data && error.response.data.message)
                setError(error.response.data.message);
            else
                setError('Login failed');
        }
    };

    const setAuthCookie = (token) => {
        Cookies.set('ataivajwt', token, {
            domain: cookie_domain,
            path: '/',
            sameSite: 'strict',
            secure: true,
            expires: 3
        });
    };

    return (
        <form onSubmit={handleSubmit} className="auth-form">
            <div style={{textAlign:'center', padding:'15px', borderBottom:'2px solid'}}>
                <img src={logo1} height="40px" />
            </div>
            <br/>
            <h2>Login</h2>
            {message && message=="email-verified" && <p style={{color:'green', marginBottom:'5px'}}><FaCheckCircle /> Your email has been verified.</p>}
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
            />
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
            />
            <button type="submit">Login</button>
            {error && <p style={{marginTop:"10px", color:'red'}}>{error}.</p>}

            <br/>
            <p>Don't have an account? <a href="/sign-up">Sign up</a></p>
        </form>
    );
}

export default Login;
