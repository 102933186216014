import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import apiService from '../../../services/apiService';
import styled from 'styled-components';

function Infrastructure() {
    // const [domains, setDomains] = useState([]);
    // const [counts, setCounts] = useState({});
    const navigate = useNavigate();

    // useEffect(() => {
    //     const fetchDomains = async () => {
    //         try {
    //             const result = await apiService.get("/domains");
    //             setDomains(result);
    //             // Fetch counts for all domains
    //             const countsObj = {};
    //             for (const domain of result) {
    //                 const count = await getCount(domain.domain);
    //                 countsObj[domain.domain] = count;
    //             }
    //             setCounts(countsObj);
    //         } catch (error) {
    //             console.error("Error fetching domains:", error);
    //         }
    //     };
    //     fetchDomains();

    //     const intervalId = setInterval(fetchDomains, 10000); // 10,000 milliseconds = 10 seconds
    //     // Clear interval on component unmount
    //     return () => clearInterval(intervalId);
    // }, []);

    const showAddInfra = () => {
        navigate('/infra/add');
    };

    // const showSetupDomain = (domain) => {
    //     navigate('/rum/'+domain+'/setup');
    // };

    // const showLiveDomain = (domain) => {
    //     navigate('/rum/'+domain+'/live');
    // };

    // const showVisitors = (domain) => {
    //     navigate('/rum/'+domain+'/visitors');
    // };

    // const getCount = async (domain) => {
    //     try {
    //         const result = await apiService.post("/live/count", { domain });
    //         return result.count;
    //     } catch (error) {
    //         return "?";
    //     }
    // };

    const Table = styled.div`
        width: 100%;
        border-collapse: collapse;
        margin: 10px 0 25px 0;
    `;

    const TableHeader = styled.div`
        display: flex;
        background-color: #777;
        border-radius: 10px 10px 0 0;
        color: #000;
        font-weight: bold;
        padding: 10px;
        text-align: left;
    `;

    const TableRow = styled.div`
        display: flex;
        padding: 10px;
        background-color: #f1f1f1;
        transition: all 0.2s ease;
        cursor: pointer;

        &:hover {
            background-color: #e3e3e3;
        }
    `;

    const TableCell = styled.div`
        padding: 10px;
        color: #000;
        text-align: left;

        &:nth-child(1) {
            flex: 0 0 auto;
            width: 50px; /* Adjust width as needed */
        }

        &:nth-child(2) {
            flex: 1;
            // &:hover {
            //     text-decoration: underline;
            // }
        }

        &:nth-child(3) {
            // flex: 0 0 auto;
            // text-align: right;
            // &:hover {
            //     text-decoration: underline;
            // }
        }

        &:nth-child(4) {
            // flex: 0 0 auto;
            // text-align: right;
            // &:hover {
            //     text-decoration: underline;
            // }
        }
    `;


    return (
        <div>
            <h2>Infrastructure Monitoring</h2>

            <h3>Coming Soon</h3>

            {/* <Table>
                <TableHeader>
                    <TableCell>Live</TableCell>
                    <TableCell>Domain</TableCell>
                    <TableCell>Visitors</TableCell>
                    <TableCell>Setup</TableCell>
                </TableHeader>
                
            </Table>

            <button onClick={showAddInfra}>Add Infrastructure</button> */}
        </div>
    );
}

export default Infrastructure;
