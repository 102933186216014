import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../assets/logo1.png';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { GrAppsRounded } from 'react-icons/gr';
import { FaServer } from 'react-icons/fa';
import { CgWebsite } from 'react-icons/cg';


const LeftNavContainer = styled.div`
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    width: 200px;
    min-width: 200px;
    background-color: #252525;
    transition: width 0.3s ease;
    overflow: hidden;
    position: relative;
`;

const NavItem = styled(NavLink)`
    padding: 10px;
    cursor: pointer;
    width: 100%;
    text-align: left;
    font-size: 1em;
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    span {
        padding-left: 10px !important;
        font-size: 0.8em;
    }
    &:hover {
        background-color: #444;
    }
    &.active {
        background-color: #444;
        border-right: 4px solid #a70091;
    }
    &.disabledLHN {
        color: #6e6e6e !important;
    }
`;

const NavGroupTitle = styled.div`
    padding: 10px;
    width: 100%;
    color: #bbb;
    font-weight: bold;
    text-align: left;
    background-color: #111;
`;

const ToggleButton = styled.button`
    margin: 10px 0;
    padding: 5px;
    cursor: pointer;
`;

const LogoImage = styled.div`
    margin: 0 auto;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
`;

const UserInfo = styled.div`
    position: fixed;
    bottom: 10px;
    left: 10px;
    color: #fff;
`;

function LeftHandNav({ user, handleLogout }) {

    const navigate = useNavigate();

    const handleLogoClick = () => {
        navigate('/');
    };


    return (
        <LeftNavContainer>
            <LogoImage>
                <img 
                    src={logo} 
                    width="60%"
                    alt="Logo" 
                    className="topnav-bar-logo" 
                    onClick={handleLogoClick} // Handle click event
                />
            </LogoImage>

            {/* <NavGroupTitle>- Welcome</NavGroupTitle> */}
            {/* <NavItem to="/dashboard" activeclassname="active">Dashboard</NavItem> */}
            {/* <NavItem to="/dashboard" activeclassname="active">Dashboard</NavItem> */}
            {/* <NavGroupTitle>- Browser</NavGroupTitle> */}
            <NavItem to="/apm" activeclassname="active" className="disabledLHN"><GrAppsRounded /><span>Application Performance Monitoring</span></NavItem>
            <NavItem to="/infra" activeclassname="active" className="disabledLHN"><FaServer /><span>Infrastructure Monitoring</span></NavItem>
            <NavItem to="/rum" activeclassname="active"><CgWebsite /><span>Real User Monitoring</span></NavItem>
            {/* <NavItem to="/live" activeclassname="active">Live</NavItem>
            <NavItem to="/visitors" activeclassname="active">Visitors</NavItem>
            <NavItem to="/reports" activeclassname="active">Reports</NavItem> */}

            {/* <NavGroupTitle>- Backend & DevOps</NavGroupTitle> */}
            {/* <NavItem to="/servers" activeclassname="active">Servers</NavItem> */}
            {/* <NavItem to="/logs" activeclassname="active">Logs</NavItem> */}
            {/* <NavItem to="/cicd" activeclassname="active">CI/CD</NavItem> */}
            {/* <NavItem to="/docker" activeclassname="active">Docker</NavItem> */}
            {/* <NavItem to="/kubernetes" activeclassname="active">Kubernetes</NavItem> */}

            {/* <NavGroupTitle>- Cloud Spend</NavGroupTitle> */}
            {/* <NavItem to="/spend/aws" activeclassname="active">AWS</NavItem> */}
            
            <UserInfo>
                <div className="user-email">{user.email}</div>
                <button className="logout-button" onClick={handleLogout}>
                    Logout
                </button>
            </UserInfo>
        </LeftNavContainer>
    );
}

export default LeftHandNav;
