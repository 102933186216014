import React from 'react';
import styled from 'styled-components';

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100vh;
    overflow-y: auto;
    padding: 10px;
    // margin-top: 15px;
`;

function MainContent({ children }) {
    return <ContentContainer>{children}</ContentContainer>;
}

export default MainContent;
