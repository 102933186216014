import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './AuthForms.css';
import Cookies from 'js-cookie';
import logo1 from '../../assets/logo1_2.webp';


function Signup() {
    const [token, setToken] = useState(Cookies.get('ataivajwt')); // Get token from cookie
    const cookie_domain = window.location.hostname === 'localhost' ? 'localhost' : '.ataiva.com';

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== passwordConfirm) {
            setError('Passwords do not match');
            return;
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, { email, password, firstName, lastName });
            if (response.status === 201 && response.data.message === "verify email") {
                navigate('/verify-email');
            } else {
                setError('Registration failed');
            }
            // 
        } catch (error) {
            setError('Registration failed');
        }
    };

    const setAuthCookie = (token) => {
        Cookies.set('ataivajwt', token, {
            domain: cookie_domain,
            path: '/',
            sameSite: 'strict',
            secure: true,
            expires: 3
        });
    };

    return (
        <form onSubmit={handleSubmit} className="auth-form">
            <div style={{textAlign:'center', padding:'15px', borderBottom:'2px solid'}}>
                <img src={logo1} height="40px" />
            </div>
            <br/>
            
            <h2>Signup</h2>
            <input
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
            />
            <input
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
            />
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
            />
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
            />
            <input
                type="password"
                placeholder="Confirm Password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                required
            />
            <button type="submit">Signup</button>
            {error && <p>{error}</p>}

            <br/>
            <p>Already have an account? <a href="/login">Login</a></p>
        </form>
    );
}

export default Signup;
