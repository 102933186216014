import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import apiService from '../../../services/apiService';
import DomainsDropdown from '../../DomainsDropdown';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIcon from '../../../assets/marker-icon.png';
import { CgWebsite } from "react-icons/cg";
import { BiWorld } from "react-icons/bi";
import { GiModernCity } from "react-icons/gi";
import { GoBrowser, GoCrossReference } from "react-icons/go";
import { FaWifi } from 'react-icons/fa';


const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  padding: 10px;
`;

const Section = styled.div`
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const LiveCounter = styled.div`
    text-align: center;
`;

const LiveCounterCount = styled.div`
    font-weight:bold;
    display:inline-block;
    font-size:7em;
`;

const LiveCounterInfo = styled.div`
    display:inline-block;
    font-size:1.4em;
    text-align: left;
    line-height: 1em;
    color: #999;
`;

const MapContainerStyled = styled.div`
  grid-column: span 3;
//   background-color: #f9f9f9;
//   padding: 20px;
  border-radius: 20px;
  border: 1px solid transparent;
//   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  height: 500px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  background-color: #f4f4f4;
`;

const TableCell = styled.td`
  padding: 10px;
  background-color: ${props => props.bgColor};
  color: ${props => (props.bgColor ? '#fff' : '#000')};
  text-align: right;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

function LiveDomain() {
    const { domain } = useParams();
    const [liveData, setLiveData] = useState({
        count: 0,
        events: [],
        live: [],
        visitors: [],
    });

    useEffect(() => {
        const fetchLiveData = async () => {
            try {
                const result = await apiService.post("/live", { domain });
                if (result.events == null) result.events = [];
                setLiveData(result);
            } catch (error) {
                console.error("Error fetching live data:", error);
            }
        };

        fetchLiveData();
        const intervalId = setInterval(fetchLiveData, 10000);
        return () => clearInterval(intervalId);
    }, [domain]);

    const getCountPercentage = (item, total) => (item.count / total) * 100;

    const sortByCountDesc = (items) => {
        return Object.entries(items)
            .map(([key, count]) => ({ key, count }))
            .sort((a, b) => b.count - a.count);
    };

    const totalVisitors = liveData && liveData.visitors && liveData.visitors.length ? liveData.visitors.length : 0;

    // Count occurrences
    const countOccurrences = (data, key) => {
        if (!data || !data.length) return [];
        const counts = data.reduce((acc, item) => {
            const value = item[key];
            if (value) {
                acc[value] = (acc[value] || 0) + 1;
            }
            return acc;
        }, {});

        return sortByCountDesc(counts);
    };

    const countEventOccurrences = (events) => {
        if (!events || !events.length) return [];
        const eventCounts = events.reduce((acc, event) => {
            const eventJson = event.json;
            const eventKey = eventJson.host !== domain ? eventJson.href : eventJson.page;

            if (!acc[eventKey]) {
                acc[eventKey] = 0;
            }
            acc[eventKey] += 1;

            return acc;
        }, {});

        return Object.entries(eventCounts).map(([key, count]) => ({ key, count }));
    };

    const countReferrerOccurrences = (events) => {
        const referrerCounts = events.reduce((acc, event) => {
            const eventJson = event.json;
            const referrer = eventJson.referrer || 'Direct/Internal';  // Handle null or undefined referrer
            //   const referrer = eventJson.referrer || 'Direct/Unknown';  // Handle null or undefined referrer

            if (referrer) {
                acc[referrer] = (acc[referrer] || 0) + 1;
            }

            return acc;
        }, {});

        return sortByCountDesc(referrerCounts);
    };

    const referrerCounts = countReferrerOccurrences(liveData.events);


    const eventCounts = countEventOccurrences(liveData.events);
    const visitorCounts = countOccurrences(liveData.visitors, 'CountryName');
    const cityCounts = countOccurrences(liveData.visitors, 'CityName');
    const uaCounts = countOccurrences(liveData.visitors, 'UserAgent');
    const ispCounts = countOccurrences(liveData.visitors, 'ISP');
    //   const referrerCounts = countOccurrences(liveData.events, 'referrer');

    // Create markers for the map
    const markers = liveData && liveData.visitors && liveData.visitors.length ? liveData.visitors.map((visitor, index) => {
        if (visitor.Latitude && visitor.Longitude) {
            return (
                <Marker
                    key={index}
                    position={[visitor.Latitude, visitor.Longitude]}
                    icon={L.icon({
                        iconUrl: markerIcon,
                        iconSize: [25, 41],
                        iconAnchor: [12, 41],
                    })}
                >
                    <Popup>
                        <div>
                            <strong>{visitor.CountryName}</strong><br />
                            {visitor.CityName}<br />
                            {visitor.ISP}<br />
                            IP: {visitor.IP}
                        </div>
                    </Popup>
                </Marker>
            );
        }
        return null;
    }) : [];

    return (
        <div>
            <DomainsDropdown
                selectedDomain={domain}
                onSelect={(domain) => {
                    window.location.href = "/rum/" + domain + "/live";
                }}
            />
            {/* <h2>LiveDomain: {domain}</h2> */}

            <LiveCounter>
                <LiveCounterCount>{liveData.count}</LiveCounterCount>
                <LiveCounterInfo>
                    <div>
                        <strong>Bots: </strong>
                        {liveData.live.filter(item => item.is_bot).length || 0}
                    </div>
                    <div>
                        <strong>Humans: </strong>
                        {liveData.live.filter(item => !item.is_bot).length || 0}
                    </div>
                </LiveCounterInfo>
            </LiveCounter>


            <MapContainerStyled>
                {/* <h2>Visitor Locations</h2> */}
                <MapContainer
                    center={[20, 0]}
                    zoom={2}
                    scrollWheelZoom={false}
                    style={{ height: '100%', width: '100%' }}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {markers}
                </MapContainer>
            </MapContainerStyled>

            <Container>
                <Section>
                    <h2><CgWebsite /> Events / Pages</h2>
                    <Table>
                        <thead>
                            <tr>
                                <TableHeader>Event</TableHeader>
                                <TableHeader>Count</TableHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {eventCounts.map(({ key, count }, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        {key}
                                    </TableCell>
                                    <TableCell colSpan={2}>{count}</TableCell>
                                </TableRow>
                            ))}
                        </tbody>
                    </Table>
                </Section>

                <Section>
                    <h2><BiWorld /> Countries</h2>
                    <Table>
                        <thead>
                            <tr>
                                <TableHeader>Country</TableHeader>
                                <TableHeader>Count</TableHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {visitorCounts.map(({ key, count }) => (
                                <TableRow key={key}>
                                    <TableCell bgColor={`rgba(0, 128, 0, ${getCountPercentage({ count }, totalVisitors) / 100})`}>
                                        {key}
                                    </TableCell>
                                    <TableCell colSpan={2}>{count}</TableCell>
                                </TableRow>
                            ))}
                        </tbody>
                    </Table>
                </Section>

                <Section>
                    <h2><GiModernCity /> Cities</h2>
                    <Table>
                        <thead>
                            <tr>
                                <TableHeader>City</TableHeader>
                                <TableHeader>Count</TableHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {cityCounts.map(({ key, count }) => (
                                <TableRow key={key}>
                                    <TableCell bgColor={`rgba(0, 128, 0, ${getCountPercentage({ count }, totalVisitors) / 100})`}>
                                        {key}
                                    </TableCell>
                                    <TableCell colSpan={2}>{count}</TableCell>
                                </TableRow>
                            ))}
                        </tbody>
                    </Table>
                </Section>

                <Section>
                    <h2>< GoBrowser /> Browsers</h2>
                    <Table>
                        <thead>
                            <tr>
                                <TableHeader>Browsers</TableHeader>
                                <TableHeader>Count</TableHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {uaCounts.map(({ key, count }) => (
                                <TableRow key={key}>
                                    <TableCell bgColor={`rgba(0, 128, 0, ${getCountPercentage({ count }, totalVisitors) / 100})`}>
                                        {key}
                                    </TableCell>
                                    <TableCell colSpan={2}>{count}</TableCell>
                                </TableRow>
                            ))}
                        </tbody>
                    </Table>
                </Section>

                <Section>
                    <h2><FaWifi /> Service Providers</h2>
                    <Table>
                        <thead>
                            <tr>
                                <TableHeader>ISPs</TableHeader>
                                <TableHeader>Count</TableHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {ispCounts.map(({ key, count }) => (
                                <TableRow key={key}>
                                    <TableCell bgColor={`rgba(0, 128, 0, ${getCountPercentage({ count }, totalVisitors) / 100})`}>
                                        {key}
                                    </TableCell>
                                    <TableCell colSpan={2}>{count}</TableCell>
                                </TableRow>
                            ))}
                        </tbody>
                    </Table>
                </Section>

                <Section>
                    <h2><GoCrossReference /> Referrers</h2>
                    <Table>
                        <thead>
                            <tr>
                                <TableHeader>Referrer</TableHeader>
                                <TableHeader>Count</TableHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {referrerCounts.map(({ key, count }) => (
                                <TableRow key={key}>
                                    <TableCell bgColor={`rgba(0, 128, 0, ${getCountPercentage({ count }, referrerCounts.reduce((acc, curr) => acc + curr.count, 0)) / 100})`}>
                                        {key}
                                    </TableCell>
                                    <TableCell colSpan={2}>{count}</TableCell>
                                </TableRow>
                            ))}
                        </tbody>
                    </Table>
                </Section>
            </Container>
        </div>
    );
}

export default LiveDomain;
