import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import apiService from '../../../services/apiService';
import styled from 'styled-components';
import DomainsDropdown from '../../DomainsDropdown';

function Visitors() {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [lastEventId, setLastEventId] = useState(null);  // For older events
    const [firstEventId, setFirstEventId] = useState(null); // For newer events
    const [selectedVisitorId, setSelectedVisitorId] = useState(null); // ID of the selected visitor
    const [selectedVisitor, setSelectedVisitor] = useState(null);
    const { domain } = useParams();
    const [showBots, setShowBots] = useState(false);

    useEffect(() => {
        loadInitialEvents();
        const intervalId = setInterval(loadNewEvents, 10000);
        return () => clearInterval(intervalId);
    }, [domain, showBots]);

    useEffect(() => {
        if (selectedVisitorId) {
            const intervalId = setInterval(() => loadVisitor(selectedVisitorId), 5000);
            return () => clearInterval(intervalId);
        }
    }, [selectedVisitorId]);

    const filterLatestEvents = (events) => {
        const latestEvents = {};
        events.forEach(event => {
            if (!latestEvents[event.vid] || new Date(event.timestamp) > new Date(latestEvents[event.vid].timestamp)) {
                latestEvents[event.vid] = event;
            }
        });
        return Object.values(latestEvents);
    };

    const loadInitialEvents = async () => {
        setLoading(true);

        try {
            const result = await apiService.post("/visitors/events", {
                "domain": domain,
                "last_event_id": null,
                "limit": 100,
                "show_bots": showBots
            });

            const filteredEvents = filterLatestEvents(result.events);
            setEvents(filteredEvents);
            if (filteredEvents.length > 0) {
                setFirstEventId(filteredEvents[0].id);
                setLastEventId(filteredEvents[filteredEvents.length - 1].id);
            }
            setHasMore(result.events.length === 100);
        } catch (error) {
            console.error("Error fetching events:", error);
        } finally {
            setLoading(false);
        }
    };

    const loadNewEvents = async () => {
        if (loading) return;
        setLoading(true);

        try {
            const result = await apiService.post("/visitors/events", {
                "domain": domain,
                "last_event_id": firstEventId,
                "limit": 100,
                "show_bots": showBots
            });

            if (result.events.length > 0) {
                const newEvents = filterLatestEvents(result.events);
                setEvents(prevEvents => {
                    const updatedEvents = [...newEvents, ...prevEvents];
                    return filterLatestEvents(updatedEvents);
                });
                setFirstEventId(newEvents[0].id);
            }
        } catch (error) {
            console.error("Error fetching new events:", error);
        } finally {
            setLoading(false);
        }
    };

    const loadOlderEvents = async () => {
        if (loading || !hasMore) return;
        setLoading(true);

        try {
            const result = await apiService.post("/visitors/events", {
                "domain": domain,
                "last_event_id": lastEventId,
                "limit": 100,
                "show_bots": showBots
            });

            if (result.events.length > 0) {
                const newEvents = filterLatestEvents(result.events);
                setEvents(prevEvents => {
                    const updatedEvents = [...prevEvents, ...newEvents];
                    return filterLatestEvents(updatedEvents);
                });
                setLastEventId(result.events[result.events.length - 1].id);
                setHasMore(result.events.length === 100);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching older events:", error);
        } finally {
            setLoading(false);
        }
    };

    const loadVisitor = async (uuid) => {
        try {
            const result = await apiService.post("/visitor", {
                "domain": domain,
                "visitor": uuid
            });
            setSelectedVisitor(result);
        } catch (error) {
            console.error("Error fetching visitor:", error);
        }
    };

    const handleScroll = (e) => {
        if (loading || !hasMore) return;

        const { scrollTop, scrollHeight, clientHeight } = e.target.scrollingElement;
        if (scrollHeight - scrollTop === clientHeight) {
            loadOlderEvents();
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [events, loading, hasMore]);

    const handleVisitorClick = (id, vid) => {
        setSelectedVisitorId(vid);
        loadVisitor(vid);
    };

    const handleCheckboxChange = () => {
        setShowBots(prevShowBots => !prevShowBots);
    };

    const safeJsonParse = (data) => {
        try {
            return typeof data === 'string' ? JSON.parse(data) : data;
        } catch {
            return {};
        }
    };

    return (
        <div>
            <DomainsDropdown selectedDomain={domain} onSelect={(domain) => { window.location.href = "/rum/" + domain + "/visitors"; }} />
            <h2>Visitors</h2>
            <div>
                <input id="botsCheckbox" type="checkbox" checked={showBots} onChange={handleCheckboxChange} />
                <label htmlFor="botsCheckbox">Show Bots</label>
            </div>

            <VisitorsContainer>
                <LeftHandColumnForVisitors>
                    {events.length > 0 ? (
                        <ul>
                            {events.map(event => (
                                <VisitorItem
                                    key={event.id}
                                    isSelected={selectedVisitorId === event.vid}
                                    onClick={() => handleVisitorClick(event.id, event.vid)}
                                >
                                    {event.id} - {safeJsonParse(event.json).page} - {event.CityName} - {event.CountryName} - {event.UserAgent} - {event.ISP}
                                </VisitorItem>
                            ))}
                        </ul>
                    ) : (
                        <p>No visitors data available</p>
                    )}
                    {hasMore && (
                        <button onClick={loadOlderEvents} style={{width:'100%'}}>Load More</button>
                    )}
                </LeftHandColumnForVisitors>

                <ContentPanelForVisitorInfo>
                    {selectedVisitor ? (
                        <div>
                            <h3>Visitor Details</h3>
                            <p><strong>IP:</strong> {selectedVisitor.visitor.IP}</p>
                            <p><strong>Country:</strong> {selectedVisitor.visitor.CountryName}</p>
                            <p><strong>City:</strong> {selectedVisitor.visitor.CityName}</p>
                            <p><strong>ISP:</strong> {selectedVisitor.visitor.ISP}</p>
                            <p><strong>User Agent:</strong> {selectedVisitor.visitor.UserAgent}</p>
                            <p><strong>Is Bot:</strong> {selectedVisitor.visitor.IsBot ? 'Yes' : 'No'}</p>
                            <h4>Events</h4>
                            
                            <EventsContainer>
                                <EventRow>
                                    <EventHeader>Type</EventHeader>
                                    <EventHeader>URL</EventHeader>
                                    <EventHeader>Referrer</EventHeader>
                                    <EventHeader>Timestamp</EventHeader>
                                </EventRow>

                                {selectedVisitor.events.map(event => (
                                    <EventRow key={event.id}>
                                        <EventColumn>{event.type}</EventColumn>
                                        <EventColumn>{safeJsonParse(event.json).href}</EventColumn>
                                        <EventColumn>{safeJsonParse(event.json).referrer || ""}</EventColumn>
                                        <EventColumn>{new Date(event.created_at).toLocaleString()}</EventColumn>
                                    </EventRow>
                                ))}
                            </EventsContainer>
                        </div>
                    ) : (
                        <NoVisitorSelected>Select a visitor to see details</NoVisitorSelected>
                    )}
                </ContentPanelForVisitorInfo>
            </VisitorsContainer>
        </div>
    );
}

const VisitorsContainer = styled.div`
  display: flex;
  height: 90vh; /* Full viewport height */
`;

const LeftHandColumnForVisitors = styled.div`
    float: left;
    width: 30%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    // padding-right: 1rem;
    border-right: 1px solid #ccc;
`;

const VisitorItem = styled.li`
    list-style: none;
    padding: 0.5rem;
    background-color: ${props => props.isSelected ? 'lightgray !important' : 'transparent'};
    border-right: 4px solid ${props => props.isSelected ? '#a70091' : 'transparent'};
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 0.8em;

    &:hover {
        background-color: #f4f4f4;
    }
`;

const ContentPanelForVisitorInfo = styled.div`
    float: right;
    width: 70%;
    padding-left: 2rem;

    height: 100%;
    overflow-y: auto;
    padding: 20px;
`;

const NoVisitorSelected = styled.p`
    font-style: italic;
`;

const EventsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5rem;
    margin-top: 1rem;
`;

const EventRow = styled.div`
    display: contents;
`;

const EventHeader = styled.div`
    font-weight: bold;
`;

const EventColumn = styled.div`
`;

export default Visitors;
