import axios from 'axios';
import Cookies from 'js-cookie';

// Create an Axios instance
const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // Replace with your backend API base URL
    timeout: 10000, // Optional: Set a timeout for requests
});

// Add a request interceptor to include the JWT token in headers
apiClient.interceptors.request.use(
    (config) => {
        const token = Cookies.get('ataivajwt')
        if (token) {
            config.headers.Authorization = `${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor to handle errors
apiClient.interceptors.response.use(
    (response) => {
        return response.data; // Return only the data part of the response
    },
    (error) => {
        // Handle errors as needed
        if (error.response && error.response.status === 401) {
            // Handle unauthorized errors (e.g., redirect to login)
            // window.location.href = '/login'; // Adjust as necessary
            console.log(error)
        }
        return Promise.reject(error);
    }
);

const apiService = {
    // Example GET request
    get: (url, params = {}) => {
        return apiClient.get(url, { params });
    },

    // Example POST request
    post: (url, data = {}) => {
        return apiClient.post(url, data);
    },

    // Example PUT request
    put: (url, data = {}) => {
        return apiClient.put(url, data);
    },

    // Example DELETE request
    delete: (url, params = {}) => {
        return apiClient.delete(url, { params });
    }
};

export default apiService;
