import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import apiService from '../services/apiService';
import { FaPlay } from 'react-icons/fa';
import { IoMdPeople } from "react-icons/io";
import { BiSolidCommentError, BiSolidReport } from 'react-icons/bi';
import { GrDocumentConfig } from "react-icons/gr";



// Styled components defined outside of the component
const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownToggle = styled.button`
  background-color: #fff;
  border: 1px solid #ccc;
  color: #000;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
`;

const DropdownMenu = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  display: ${(props) => (props.isopen ? 'block' : 'none')};
`;

const DomainItem = styled.li`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Arrow = styled.span`
  margin-left: 10px;
  border: solid #333;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: ${(props) => (props.isopen ? 'rotate(-135deg)' : 'rotate(45deg)')};
  transition: transform 0.2s ease;
`;

const QuickSwitchText = styled.span`
  font-weight: bold;
`;

const QuickSwitchLinks = styled.span`
  margin-left: 10px;
`;

const B = styled.span`
  margin-left: 10px;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  color: ${props => props.active ? '#a70091' : '#000'};
  font-weight: ${props => props.active ? 'bold' : 'normal'};
`;

export default function DomainsDropdown({ selectedDomain, onSelect }) {
  const [domains, setDomains] = useState([]);
  const [isopen, setIsOpen] = useState(0);

  useEffect(() => {
    apiService.get('/domains')
      .then((response) => {
        const sortedDomains = response.sort((a, b) => {
          const domainA = a.domain.toLowerCase();
          const domainB = b.domain.toLowerCase();
          if (domainA < domainB) return -1;
          if (domainA > domainB) return 1;
          return 0;
        });
        setDomains(Array.isArray(sortedDomains) ? sortedDomains : []);
      })
      .catch((error) => {
        console.error('Error fetching domains:', error);
        setDomains([]);
      });
  }, []);

  const handleDropdownToggle = () => {
    setIsOpen(previsopen => !previsopen);
  };

  const handleDomainSelect = (domain) => {
    onSelect(domain);
    setIsOpen(false);
  };

  const currentPath = window.location.pathname;

  return (
    <>
      <QuickSwitchText>QuickSwitch: </QuickSwitchText>
      <DropdownContainer>
        <DropdownToggle onClick={handleDropdownToggle}>
          {selectedDomain || 'Select a domain'}
          <Arrow isopen={isopen} />
        </DropdownToggle>
        <DropdownMenu isopen={isopen}>
          {domains.length === 0 ? (
            <DomainItem>No domains available</DomainItem>
          ) : (
            domains.map((domain) => (
              <DomainItem
                key={domain.id}
                onClick={() => handleDomainSelect(domain.domain)}
              >
                {domain.domain}
              </DomainItem>
            ))
          )}
        </DropdownMenu>
      </DropdownContainer>
      <QuickSwitchLinks>
        <B active={currentPath.includes("/live")} onClick={() => window.location.href = `/rum/${selectedDomain}/live`}><FaPlay /> Live</B>
        <B active={currentPath.includes("/visitors")} onClick={() => window.location.href = `/rum/${selectedDomain}/visitors`}><IoMdPeople /> Visitors</B>
        <B active={currentPath.includes("/errors")} onClick={() => window.location.href = `/rum/${selectedDomain}/errors`}><BiSolidCommentError /> Errors</B>
        <B active={currentPath.includes("/reports")} onClick={() => window.location.href = `/rum/${selectedDomain}/reports`}><BiSolidReport /> Reports</B>
        <B active={currentPath.includes("/setup")} onClick={() => window.location.href = `/rum/${selectedDomain}/setup`}><GrDocumentConfig /> Setup</B>
      </QuickSwitchLinks>
    </>
  );
}
