import React, { useState } from 'react';
import apiService from '../../../services/apiService';
import { useNavigate } from 'react-router-dom';

function AddDomain() {

    const [ error, setError ] = useState("")
    const navigate = useNavigate();

    const saveNewDomain = () => {
        let domain_name = document.getElementById('txtAddDomain').value
        // validate domain

        if (domain_name === "") {
            setError("Please enter a domain name")
            return
        }

        // save to db, then get the id and open the modify page
        const result = apiService.post("/domain/add", {
            'type': 'domain_add',
            domain: domain_name
        })
        
        navigate('/rum/'+domain_name+'/setup');
    }

    return <div>
                <h2>Add Domain</h2>

                <div>
                    <input type="text" id="txtAddDomain" placeholder="example.com" />
                    { error && <div className="error">{error}</div>}
                </div>
                
                <button onClick={() => saveNewDomain()}>Save</button>
            </div>;
}

export default AddDomain;
