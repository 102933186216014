import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

function Logout() {
    const navigate = useNavigate();
    const cookie_domain = window.location.hostname === 'localhost' ? 'localhost' : '.ataiva.com';

    useEffect(() => {
        // Remove the token cookie
        Cookies.remove('ataivajwt', {
            domain: cookie_domain,
            path: '/',
            sameSite: 'strict',
            secure: true,
            expires: 3
        });

        setTimeout(() => {
            window.location.href = "/";
        })
    }, [navigate]);

    return (
        <>Logging out..</>
    );
}

export default Logout;
