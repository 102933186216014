import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import './Root.css';
import LeftHandNav from './layout/LeftHandNav';
import MainContent from './layout/MainContent';
import TopNavBar from './layout/TopNavBar';

function Root({ token, user, handleLogout }) {

    return (
        <div className="root-container">
            {token && user ? (
                <>
                    {/* <TopNavBar user={user} handleLogout={handleLogout} /> */}
                    <div className="app-container">
                        <LeftHandNav user={user} handleLogout={handleLogout} />
                        <MainContent>
                            {/* Main content based on selected section */}
                            <Outlet />
                        </MainContent>
                    </div>
                </>
            ) : (
                <></>
            )}
        </div>
    );
}

export default Root;
