import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import apiService from '../../../services/apiService';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import DomainsDropdown from '../../DomainsDropdown';

const CodeBlock = styled.pre`
    color: #00e200;
    padding: 15px;
    border-radius: 4px;
    margin: 10px 0px;
    background-color: rgb(0 0 0);
`;

function SetupDomain({ user }) {
    const { domain } = useParams();
    const navigate = useNavigate();

    const deleteDomain = async (domain) => {
        const response = await apiService.delete(`/domain/delete`, {
            domain: domain
        })
        navigate('/live');
    }

    const api_location = window.location.hostname == "localhost" ? "http://localhost:8000/wa.js?domain=http://localhost:8000" : "https://api.ataiva.com/wa.js";

    return <div>
                <DomainsDropdown selectedDomain={domain} onSelect={(domain) => {window.location.href="/rum/"+domain+"/setup"}} />
                <h2>Setup Domain ({domain})</h2>

                <div>
                    <CodeBlock>
{`<script>
    (function() {
        var script = document.createElement('script');
        script.src = '`+api_location+`';
        script.onload = function() {
            ataiva.wa.init('`+user.uuid+`', '`+domain+`', {'TrackErrors':true, 'TrackWarnings':true});
            
            // Track an event
            ataiva.wa.Track({
                type: 'page_view',
                domain: '`+domain+`',
                json: {
                    page: window.location.pathname,
                    href: window.location.href
                }
            });
        };
        document.head.appendChild(script);
    })();
</script>`}
                    </CodeBlock>
                </div>
                <div>
                    <button onClick={() => deleteDomain(domain)}>Delete</button>
                </div>
            </div>;
}

export default SetupDomain;
