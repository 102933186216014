import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './AuthForms.css';
import logo1 from '../../assets/logo1_2.webp';
import { MdOutlineMailLock } from "react-icons/md";
import apiService from '../../services/apiService';
import { MdError } from "react-icons/md";


function VerifyEmail() {
    const [ error, setError ] = useState("");

    const { uuid, email } = useParams();

    useEffect(() => {
        console.log(uuid, email)
        const verifyEmail = async () => {
            try {
                const response = await apiService.post('/verify-email', { uuid, email });
                window.location.href = "/login/email-verified";
                // Optionally, redirect the user or perform additional actions
            } catch (error) {
                setError(error.response.data);
                // Handle error, e.g., display an error message
            }
        };

        if (uuid && email)
            verifyEmail();
    }, [uuid, email]);

    return (
        <div className="auth-form">
            <div style={{textAlign:'center', padding:'15px', borderBottom:'2px solid'}}>
                <img src={logo1} height="40px" />
            </div>
            <br/>
            
            <p style={{textAlign:'center'}}>
                <MdOutlineMailLock style={{color:'green', fontSize:'2em', position:'relative', top:'10px'}} /> Please verify your email address..
            </p>
            <br/>
            {error && <p style={{color:'red', textAlign: 'center'}}><MdError /> {error}</p>}

        </div>
    );
}

export default VerifyEmail;
