import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import DomainsDropdown from '../../DomainsDropdown';
import { useParams } from 'react-router-dom';
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
// import 'leaflet/dist/leaflet.css';
// import L from 'leaflet';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar, ResponsiveContainer } from 'recharts';
import markerIcon from '../../../assets/marker-icon.png'; // Adjust the path as needed
import apiService from '../../../services/apiService';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px;
`;

const Section = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  height: 100%;
`;

const ReportsCounter = styled.div`
  font-size: 3em;
  text-align: center;
  margin-bottom: 20px;
`;

const MapContainerStyled = styled.div`
  grid-column: span 2;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  height: 500px;
`;

const ReportsDomain = () => {
  const { domain } = useParams();
  const [reportsData, setReportsData] = useState({
    count: 0,
    events: [],
    visitors: [],
    referralSources: [],
    userAgents: [],
    timeSeriesData: [],
    countryData: [],
    cityData: [],
    ispData: [],
  });

  useEffect(() => {
    fetchReportData();
  }, [domain]);

  const fetchReportData = async () => {
    try {
      const response = await apiService.post('/reports', { domain });
      
      // Proceed with data processing
      const data = response || {};
      const formattedData = {
        count: data.counts || 0,
        events: data.page_views || [],
        visitors: data.visitors || [],
        referralSources: data.refs || [],
        userAgents: data.userAgents || [], 
        timeSeriesData: data.timeSeriesData || [],
        countryData: data.visitors_by_country ? data.visitors_by_country.map(item => ({
          country: item.location,
          count: item.count
        })) : [],
        cityData: data.visitors_by_city ? data.visitors_by_city.map(item => ({
          city: item.location,
          count: item.count
        })) : [],
        ispData: data.isps || [] 
      };
  
      setReportsData(formattedData);
    } catch (err) {
      console.error("Error fetching report data:", err);
    }
  };

  // const markers = reportsData.visitors.map((visitor, index) => (
  //   visitor.Latitude && visitor.Longitude ? (
  //     <Marker
  //       key={index}
  //       position={[visitor.Latitude, visitor.Longitude]}
  //       icon={L.icon({
  //         iconUrl: markerIcon,
  //         iconSize: [25, 41],
  //         iconAnchor: [12, 41],
  //       })}
  //     >
  //       <Popup>
  //         <div>
  //           <strong>{visitor.CountryName}</strong><br />
  //           {visitor.CityName}<br />
  //           {visitor.ISP}<br />
  //           IP: {visitor.IP}
  //         </div>
  //       </Popup>
  //     </Marker>
  //   ) : null
  // ));

  return (
    <div>
      <DomainsDropdown 
        selectedDomain={domain} 
        onSelect={(domain) => {
          window.location.href = "/rum/" + domain + "/reports";
        }}
      />

      <ReportsCounter>{reportsData.count} Total Events</ReportsCounter>

      {/* <MapContainerStyled>
        <h2>Visitor Locations</h2>
        <MapContainer
          center={[20, 0]}
          zoom={2}
          scrollWheelZoom={false}
          style={{ height: '100%', width: '100%' }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {markers}
        </MapContainer>
      </MapContainerStyled> */}

      <Container>
              <Section>
          <h3>Events Over Time</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={reportsData.timeSeriesData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="events" stroke="#8884d8" />
              <Line type="monotone" dataKey="visitors" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </Section>

        <Section>
          <h3>Top Countries</h3>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={reportsData.countryData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="country" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </Section>

        <Section>
          <h3>Top Cities</h3>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={reportsData.cityData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="city" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </Section>

        <Section>
          <h3>Service Providers</h3>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={reportsData.ispData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="isp" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </Section>

        <Section>
          <h3>Referral Sources</h3>
          <ul>
            {reportsData.referralSources.map((source, index) => (
              <li key={index}>
                {source.page || "Direct"}
                <span style={{ float: 'right' }}>{source.count}</span>
              </li>
            ))}
          </ul>
        </Section>

        <Section>
          <h3>User Agents</h3>
          <ul>
            {reportsData.userAgents.map((agent, index) => (
              <li key={index}>
                {agent.userAgent}
                <span style={{ float: 'right' }}>{agent.count}</span>
              </li>
            ))}
          </ul>
        </Section>
      </Container>
    </div>
  );
};

export default ReportsDomain;
