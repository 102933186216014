import React from 'react';
import { useNavigate, NavLink } from 'react-router-dom'; // Import useNavigate hook
import './TopNavBar.css';
import logo from '../../assets/logo1.png';
import styled from 'styled-components';

const NavItem = styled(NavLink)`
    padding: 15px;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    // display: flex;
    // align-items: center;
    &:hover {
        background-color: #444;
    }
    &.active {
        background-color: #444;
        border-bottom: 4px solid #a70091;
    }
`;


function TopNavBar({ user, handleLogout }) {
    
    const navigate = useNavigate(); // Initialize useNavigate hook

    const handleLogoClick = () => {
        navigate('/'); // Navigate to the homepage
    };

    return (
        <div className="topnav-bar">
            <div className="logo-container">
                <img 
                    src={logo} 
                    alt="Logo" 
                    className="topnav-bar-logo" 
                    onClick={handleLogoClick} // Handle click event
                />
                {/* <div className="logo-text">Analytics</div> */}
            </div>

            <div className="nav-links">
                {/* Add your navigation links here */}
                {/* Example: <a href="#">Link 1</a> */}

                {/* <NavGroupTitle>- Welcome</NavGroupTitle> */}
                {/* <NavItem to="/dashboard" activeclassname="active">Dashboard</NavItem> */}
                {/* <NavItem to="/dashboard" activeclassname="active">Dashboard</NavItem> */}
                {/* <NavGroupTitle>- Browser</NavGroupTitle> */}
                <NavItem to="/apm" activeclassname="active">APM</NavItem>
                <NavItem to="/infra" activeclassname="active">Infra</NavItem>
                <NavItem to="/rum" activeclassname="active">RUM</NavItem>
                {/* <NavItem to="/live" activeclassname="active">Live</NavItem>
                <NavItem to="/visitors" activeclassname="active">Visitors</NavItem>
                <NavItem to="/reports" activeclassname="active">Reports</NavItem> */}

                {/* <NavGroupTitle>- Backend & DevOps</NavGroupTitle> */}
                {/* <NavItem to="/servers" activeclassname="active">Servers</NavItem> */}
                {/* <NavItem to="/logs" activeclassname="active">Logs</NavItem> */}
                {/* <NavItem to="/cicd" activeclassname="active">CI/CD</NavItem> */}
                {/* <NavItem to="/docker" activeclassname="active">Docker</NavItem> */}
                {/* <NavItem to="/kubernetes" activeclassname="active">Kubernetes</NavItem> */}

                {/* <NavGroupTitle>- Cloud Spend</NavGroupTitle> */}
                {/* <NavItem to="/spend/aws" activeclassname="active">AWS</NavItem> */}
            </div>

            <div className="user-info">
                <div className="user-email">{user.email}</div>
                <button className="logout-button" onClick={handleLogout}>
                    Logout
                </button>
            </div>
        </div>
    );
}

export default TopNavBar;
