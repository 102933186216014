import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import apiService from '../../../services/apiService';
import styled from 'styled-components';
import DomainsDropdown from '../../DomainsDropdown';

function Errors() {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [lastEventId, setLastEventId] = useState(null);
    const [newestEventId, setNewestEventId] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null); // State for active event
    const { domain } = useParams();
    const listRef = useRef(null); // Ref for the list container
    const pollingIntervalRef = useRef(null); // Ref for the polling interval

    useEffect(() => {
        loadInitialEvents();
        // Set up polling for new events every 10 seconds
        pollingIntervalRef.current = setInterval(() => {
            loadNewerEvents();
        }, 10000);

        return () => {
            // Clear the interval on component unmount
            if (pollingIntervalRef.current) {
                clearInterval(pollingIntervalRef.current);
            }
        };
    }, [domain]);

    const loadInitialEvents = async () => {
        setLoading(true);

        try {
            const result = await apiService.post("/errors", {
                "domain": domain,
                "last_event_id": null,
                "limit": 100
            });

            if (result.events.length > 0) {
                setEvents(result.events);
                setNewestEventId(result.events[0].id); // Set the newest event ID for prepending
                setLastEventId(result.events[result.events.length - 1].id);
                setHasMore(result.events.length === 100);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error("Failed to fetch errors:", error);
        } finally {
            setLoading(false);
        }
    };

    const loadOlderEvents = async () => {
        if (loading || !hasMore) return;
        setLoading(true);

        try {
            const result = await apiService.post("/errors", {
                "domain": domain,
                "last_event_id": lastEventId,
                "limit": 100
            });

            if (result.events.length > 0) {
                setEvents(prevEvents => [...prevEvents, ...result.events]);
                setLastEventId(result.events[result.events.length - 1].id);
                setHasMore(result.events.length === 100);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error("Failed to fetch older errors:", error);
        } finally {
            setLoading(false);
        }
    };

    const loadNewerEvents = async () => {
        if (loading) return;
        setLoading(true);

        try {
            const result = await apiService.post("/errors", {
                "domain": domain,
                "last_event_id": newestEventId,
                "limit": 100
            });

            if (result.events.length > 0) {
                setEvents(prevEvents => [...result.events, ...prevEvents]);
                setNewestEventId(result.events[0].id);
                setHasMore(result.events.length === 100);
            }
        } catch (error) {
            console.error("Failed to fetch newer errors:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleScroll = (e) => {
        if (loading || !hasMore) return;

        const { scrollTop, scrollHeight, clientHeight } = e.target.scrollingElement;
        if (scrollHeight - scrollTop === clientHeight) {
            loadOlderEvents();
        }
    };

    const handleScrollTop = (e) => {
        if (loading || !newestEventId) return;

        const { scrollTop } = e.target.scrollingElement;
        if (scrollTop === 0) {
            loadNewerEvents();
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('scroll', handleScrollTop);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('scroll', handleScrollTop);
        };
    }, [events, loading, hasMore, newestEventId]);

    const handleEventClick = (event) => {
        setSelectedEvent(event); // Set the clicked event as active
    };

    const LeftHandColumnForErrors = styled.div`
        width: 30%;
        float: left;
        padding: 10px;
        max-height: 80vh;
        overflow-y: auto;
    `;

    const ContentPanelForErrorInfo = styled.div`
        width: 70%;
        float: right;
        padding: 10px;
        border-left: 1px solid #ccc;
        overflow-x: hidden;
    `;

    const ErrorItem = styled.li`
        list-style: none;
        padding: 0.5rem;
        background-color: ${props => props.isselected ? 'lightgray !important' : 'transparent'};
        border-right: 4px solid ${props => props.isselected ? '#a70091' : 'transparent'};
        cursor: pointer;
        transition: all 0.2s ease;
        font-size: 0.8em;

        &:hover {
            background-color: #f4f4f4;
        }
    `;

    return (
        <div>
            <DomainsDropdown selectedDomain={domain} onSelect={(domain) => { window.location.href = "/rum/" + domain + "/errors"; }} />
            <h2>Errors</h2>

            <div>
                <LeftHandColumnForErrors ref={listRef}>
                    {events.length > 0 ? (
                        <ul>
                            {events.map(event => {
                                let errorMessage = "No message available"; // Default message
                                try {
                                    if (event.err_short) {
                                        // If err_short is present, use it directly as the error message
                                        errorMessage = event.err_short;
                                    }
                                    // Additional check for err_json
                                    else if (event.err_json) {
                                        const errorData = JSON.parse(event.err_json);
                                        errorMessage = errorData.message || "No message available";
                                    }
                                } catch (err) {
                                    console.error("Error parsing JSON:", err);
                                }

                                return (
                                    <ErrorItem 
                                        key={event.id} 
                                        isselected={selectedEvent && selectedEvent.id === event.id} 
                                        onClick={() => handleEventClick(event)}
                                    >
                                        {event.id} - {errorMessage} - {new Date(event.created_at).toLocaleString()}
                                    </ErrorItem>
                                );
                            })}
                        </ul>
                    ) : (
                        <p>No error data available</p>
                    )}
                    {hasMore && (
                        <button onClick={loadOlderEvents} style={{width:'100%'}}>Load More</button>
                    )}
                </LeftHandColumnForErrors>

                <ContentPanelForErrorInfo>
                    {selectedEvent ? (
                        <div>
                            <h3>Error Details</h3>
                            <p><strong>ID:</strong> {selectedEvent.id}</p>
                            <p><strong>Created At:</strong> {new Date(selectedEvent.created_at).toLocaleString()}</p>
                            <div><strong>Error JSON:</strong> <pre style={{whiteSpace:'break-spaces'}}>{JSON.stringify(JSON.parse(selectedEvent.err_json), null, 2)}</pre></div>
                            {/* Add more fields here based on your data structure */}
                        </div>
                    ) : (
                        <p>Select an error to view details</p>
                    )}
                </ContentPanelForErrorInfo>
            </div>
        </div>
    );
}

export default Errors;
